import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const TagsWrapper = styled.div`
    padding: 0 0 20px;
    span {
        padding: 0 5px;
    }
`;
const PostTag = styled.span`
    a {
        color: #c33dd4;
        text-decoration: underline dotted #c33dd4;
    }
`;
const Tags = ({ tags }) =>
    tags.length && (
        <TagsWrapper>
            <span role="img" aria-label="tags">
                🏷
            </span>
            {tags.map(tag => (
                <PostTag key={tag}>
                    <Link to={`/tags/${tag}`}>{tag}</Link>
                </PostTag>
            ))}
        </TagsWrapper>
    );

export default Tags;
